<template>
  <main>
    <section class="section" style="padding-bottom: 0;">
      <nav class="breadcrumb is-medium" aria-label="breadcrumbs">
        <ul>
          <sqr-crumb name="broker" :bid="bid">{{$t('breadcrumb_broker', broker)}}</sqr-crumb>
          <sqr-crumb name="emails-templates" :bid="bid" label="breadcrumb_emails_templates"/>
        </ul>
      </nav>

      <div class="contain">
        <sqr-router-link
          icon="plus"
          label="emails_templates_add"
          to-name="emails-templates-add"
          :to-bid="bid"
          color="primary"
          size="normal"
        />
      </div>
    </section>

    <section class="section">
      <table class="table is-fullwidth">
        <thead>
          <tr>
            <th>{{$t('emails_template_id')}}</th>
            <th>{{$t('emails_template_type')}}</th>
            <th>{{$t('emails_template_subject')}}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="template in records" :key="template.id">
            <td>
              <sqr-router-link
                :label-raw="template.name"
                to-name="emails-template"
                :to-bid="bid"
                :to-id="template.id"
                is-link
              />
            </td>
            <td>{{template.type}}</td>
            <td>{{template.subject}}</td>
          </tr>
        </tbody>
      </table>
    </section>
  </main>
</template>

<script>
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
export default {
  name: 'EmailsTemplates',
  props: {
    bid: String
  },
  computed: {
    ...mapGetters('brokers', ['broker']),
    ...mapGetters('emailsTemplates', ['records', 'loading', 'error'])
  },
  mounted () {
    const path = ['brokers', this.bid, 'emails-templates'];
    this.sub({
      path,
      orderBy: [['name', 'asc']]
    });
  },
  methods: {
    ...mapActions('emailsTemplates', ['sub'])
  }
}
</script>
